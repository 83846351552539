/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const adminListUsersByEnterpriseID = /* GraphQL */ `
  query AdminListUsersByEnterpriseID(
    $enterpriseID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminListUsersByEnterpriseID(
      enterpriseID: $enterpriseID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sub
        name
        firstName
        lastName
        email
        enabled
        status
        roles
        lastActive
        deletedAt
        enterpriseID
        phoneNumber
        designation
        optin
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        groupMemberships {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const adminListGroupsByEnterpriseID = /* GraphQL */ `
  query AdminListGroupsByEnterpriseID(
    $enterpriseID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminListGroupsByEnterpriseID(
      enterpriseID: $enterpriseID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        maxMembers
        deletedAt
        enterpriseID
        membersCount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        members {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const listInvitationsByEnterpriseID = /* GraphQL */ `
  query ListInvitationsByEnterpriseID(
    $inviterEnterpriseID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvitationsByEnterpriseID(
      inviterEnterpriseID: $inviterEnterpriseID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        status
        inviterID
        inviterEnterpriseID
        inviteeID
        roles
        groupID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        inviter {
          id
          sub
          name
          firstName
          lastName
          email
          enabled
          status
          roles
          lastActive
          deletedAt
          enterpriseID
          phoneNumber
          designation
          optin
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const adminGetUser = /* GraphQL */ `
  query AdminGetUser($id: ID!) {
    adminGetUser(id: $id) {
      id
      sub
      name
      firstName
      lastName
      email
      enabled
      status
      roles
      lastActive
      deletedAt
      enterpriseID
      phoneNumber
      designation
      optin
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      groupMemberships {
        items {
          id
          memberID
          groupID
          isManager
          isLearner
          userSub
          deletedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const adminGetGroup = /* GraphQL */ `
  query AdminGetGroup($id: ID!) {
    adminGetGroup(id: $id) {
      id
      name
      maxMembers
      deletedAt
      enterpriseID
      membersCount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      members {
        items {
          id
          memberID
          groupID
          isManager
          isLearner
          userSub
          deletedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const adminGetGroupMember = /* GraphQL */ `
  query AdminGetGroupMember($id: ID!) {
    adminGetGroupMember(id: $id) {
      id
      memberID
      groupID
      isManager
      isLearner
      userSub
      deletedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      member {
        id
        sub
        name
        firstName
        lastName
        email
        enabled
        status
        roles
        lastActive
        deletedAt
        enterpriseID
        phoneNumber
        designation
        optin
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        groupMemberships {
          nextToken
          startedAt
        }
      }
      group {
        id
        name
        maxMembers
        deletedAt
        enterpriseID
        membersCount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        members {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const adminListEnterpriseProfiles = /* GraphQL */ `
  query AdminListEnterpriseProfiles(
    $filter: ModelEnterpriseProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminListEnterpriseProfiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        licenseCount
        membership {
          name
          status
          createdAt
          updatedAt
        }
        subscription {
          id
          provider
          providerSubID
          providerCustomerID
          status
          currencyCode
          trialStart
          trialEnd
          currentTermStart
          currentTermEnd
          nextBillingAt
          cancelledAt
          billingPeriod
          billingPeriodUnit
          resourceVersion
          createdAt
          updatedAt
        }
        courseContentVersion
        deletedAt
        numberOfEmployees
        location
        hiddenCourses
        scormCloudAppID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const adminSyncEnterpriseProfiles = /* GraphQL */ `
  query AdminSyncEnterpriseProfiles(
    $filter: ModelEnterpriseProfileFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    adminSyncEnterpriseProfiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        licenseCount
        membership {
          name
          status
          createdAt
          updatedAt
        }
        subscription {
          id
          provider
          providerSubID
          providerCustomerID
          status
          currencyCode
          trialStart
          trialEnd
          currentTermStart
          currentTermEnd
          nextBillingAt
          cancelledAt
          billingPeriod
          billingPeriodUnit
          resourceVersion
          createdAt
          updatedAt
        }
        courseContentVersion
        deletedAt
        numberOfEmployees
        location
        hiddenCourses
        scormCloudAppID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getRBACMatrix = /* GraphQL */ `
  query GetRBACMatrix($sub: String) {
    getRBACMatrix(sub: $sub) {
      items {
        resource {
          type
          id
        }
        roles
        userID
      }
    }
  }
`;
export const adminListReportResultsByEnterpriseID = /* GraphQL */ `
  query AdminListReportResultsByEnterpriseID(
    $enterpriseID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelReportResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminListReportResultsByEnterpriseID(
      enterpriseID: $enterpriseID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        reportID
        userID
        userSub
        formID
        responseID
        skillScores {
          skillID
          score
          maxScore
        }
        deletedAt
        enterpriseID
        archived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        user {
          id
          sub
          name
          firstName
          lastName
          email
          enabled
          status
          roles
          lastActive
          deletedAt
          enterpriseID
          phoneNumber
          designation
          optin
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        report {
          id
          sourceReferenceID
          sourceID
          locale
          name
          heading
          subHeading
          startCTA
          explainer
          deletedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const adminGetReportResult = /* GraphQL */ `
  query AdminGetReportResult($id: ID!) {
    adminGetReportResult(id: $id) {
      id
      reportID
      userID
      userSub
      formID
      responseID
      skillScores {
        skillID
        score
        maxScore
        subSkillScores {
          subSkillID
          score
          maxScore
          scoreKey
        }
      }
      deletedAt
      enterpriseID
      archived
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        sub
        name
        firstName
        lastName
        email
        enabled
        status
        roles
        lastActive
        deletedAt
        enterpriseID
        phoneNumber
        designation
        optin
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        groupMemberships {
          nextToken
          startedAt
        }
      }
      report {
        id
        sourceReferenceID
        sourceID
        locale
        name
        heading
        subHeading
        startCTA
        explainer
        assessment {
          source
          title
          formId
        }
        skills {
          id
          label
        }
        deletedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        source {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const adminListCourseRegistrationsByEnterpriseID = /* GraphQL */ `
  query AdminListCourseRegistrationsByEnterpriseID(
    $enterpriseID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCourseRegistrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminListCourseRegistrationsByEnterpriseID(
      enterpriseID: $enterpriseID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        courseID
        userID
        userSub
        status
        deadline
        deletedAt
        enterpriseID
        assignedByUserID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listPrivateCoursesByEnterpriseID = /* GraphQL */ `
  query ListPrivateCoursesByEnterpriseID(
    $enterpriseID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrivateCoursesByEnterpriseID(
      enterpriseID: $enterpriseID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        source
        sourceID
        locale
        courseName
        courseDescription
        starterPack {
          title
          slug
        }
        active
        modules {
          id
          source
          sourceID
          locale
          moduleName
          moduleDescription
          createdAt
          updatedAt
        }
        tags
        categories
        courseTileImage {
          title
          href
        }
        courseHeroBannerImage {
          title
          href
        }
        deletedAt
        type
        enterpriseID
        learningStandard
        accessType
        status
        uploadType
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listPublicCourses = /* GraphQL */ `
  query ListPublicCourses(
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublicCourses(
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        source
        sourceID
        locale
        courseName
        courseDescription
        starterPack {
          title
          slug
        }
        active
        modules {
          id
          source
          sourceID
          locale
          moduleName
          moduleDescription
          createdAt
          updatedAt
        }
        tags
        categories
        courseTileImage {
          title
          href
        }
        courseHeroBannerImage {
          title
          href
        }
        deletedAt
        type
        enterpriseID
        learningStandard
        accessType
        status
        uploadType
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      sub
      name
      firstName
      lastName
      email
      enabled
      status
      roles
      lastActive
      deletedAt
      enterpriseID
      phoneNumber
      designation
      optin
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      groupMemberships {
        items {
          id
          memberID
          groupID
          isManager
          isLearner
          userSub
          deletedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sub
        name
        firstName
        lastName
        email
        enabled
        status
        roles
        lastActive
        deletedAt
        enterpriseID
        phoneNumber
        designation
        optin
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        groupMemberships {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const listUsersBySub = /* GraphQL */ `
  query ListUsersBySub(
    $sub: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersBySub(
      sub: $sub
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sub
        name
        firstName
        lastName
        email
        enabled
        status
        roles
        lastActive
        deletedAt
        enterpriseID
        phoneNumber
        designation
        optin
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        groupMemberships {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const listUsersByEnterpriseID = /* GraphQL */ `
  query ListUsersByEnterpriseID(
    $enterpriseID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersByEnterpriseID(
      enterpriseID: $enterpriseID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sub
        name
        firstName
        lastName
        email
        enabled
        status
        roles
        lastActive
        deletedAt
        enterpriseID
        phoneNumber
        designation
        optin
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        groupMemberships {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        sub
        name
        firstName
        lastName
        email
        enabled
        status
        roles
        lastActive
        deletedAt
        enterpriseID
        phoneNumber
        designation
        optin
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        groupMemberships {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      name
      maxMembers
      deletedAt
      enterpriseID
      membersCount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      members {
        items {
          id
          memberID
          groupID
          isManager
          isLearner
          userSub
          deletedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        maxMembers
        deletedAt
        enterpriseID
        membersCount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        members {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncGroups = /* GraphQL */ `
  query SyncGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        maxMembers
        deletedAt
        enterpriseID
        membersCount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        members {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getGroupMember = /* GraphQL */ `
  query GetGroupMember($id: ID!) {
    getGroupMember(id: $id) {
      id
      memberID
      groupID
      isManager
      isLearner
      userSub
      deletedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      member {
        id
        sub
        name
        firstName
        lastName
        email
        enabled
        status
        roles
        lastActive
        deletedAt
        enterpriseID
        phoneNumber
        designation
        optin
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        groupMemberships {
          nextToken
          startedAt
        }
      }
      group {
        id
        name
        maxMembers
        deletedAt
        enterpriseID
        membersCount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        members {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const adminListGroupMembers = /* GraphQL */ `
  query AdminListGroupMembers(
    $filter: ModelGroupMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminListGroupMembers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        memberID
        groupID
        isManager
        isLearner
        userSub
        deletedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        member {
          id
          sub
          name
          firstName
          lastName
          email
          enabled
          status
          roles
          lastActive
          deletedAt
          enterpriseID
          phoneNumber
          designation
          optin
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        group {
          id
          name
          maxMembers
          deletedAt
          enterpriseID
          membersCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const listGroupMembersByUserSub = /* GraphQL */ `
  query ListGroupMembersByUserSub(
    $userSub: String
    $sortDirection: ModelSortDirection
    $filter: ModelGroupMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupMembersByUserSub(
      userSub: $userSub
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        memberID
        groupID
        isManager
        isLearner
        userSub
        deletedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        member {
          id
          sub
          name
          firstName
          lastName
          email
          enabled
          status
          roles
          lastActive
          deletedAt
          enterpriseID
          phoneNumber
          designation
          optin
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        group {
          id
          name
          maxMembers
          deletedAt
          enterpriseID
          membersCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncGroupMembers = /* GraphQL */ `
  query SyncGroupMembers(
    $filter: ModelGroupMemberFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGroupMembers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        memberID
        groupID
        isManager
        isLearner
        userSub
        deletedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        member {
          id
          sub
          name
          firstName
          lastName
          email
          enabled
          status
          roles
          lastActive
          deletedAt
          enterpriseID
          phoneNumber
          designation
          optin
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        group {
          id
          name
          maxMembers
          deletedAt
          enterpriseID
          membersCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getEnterpriseProfile = /* GraphQL */ `
  query GetEnterpriseProfile($id: ID!) {
    getEnterpriseProfile(id: $id) {
      id
      name
      licenseCount
      membership {
        name
        status
        createdAt
        updatedAt
      }
      subscription {
        id
        provider
        providerSubID
        providerCustomerID
        status
        entitlements {
          featureSlug
          featureType
          featureUnit
          value
        }
        currencyCode
        trialStart
        trialEnd
        currentTermStart
        currentTermEnd
        nextBillingAt
        cancelledAt
        items {
          itemPriceId
          itemType
          quantity
          unitPrice
          amount
          freeQuantity
          name
        }
        billingPeriod
        billingPeriodUnit
        resourceVersion
        createdAt
        updatedAt
      }
      courseContentVersion
      deletedAt
      numberOfEmployees
      location
      hiddenCourses
      scormCloudAppID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listEnterpriseProfiles = /* GraphQL */ `
  query ListEnterpriseProfiles(
    $filter: ModelEnterpriseProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEnterpriseProfiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        licenseCount
        membership {
          name
          status
          createdAt
          updatedAt
        }
        subscription {
          id
          provider
          providerSubID
          providerCustomerID
          status
          currencyCode
          trialStart
          trialEnd
          currentTermStart
          currentTermEnd
          nextBillingAt
          cancelledAt
          billingPeriod
          billingPeriodUnit
          resourceVersion
          createdAt
          updatedAt
        }
        courseContentVersion
        deletedAt
        numberOfEmployees
        location
        hiddenCourses
        scormCloudAppID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncEnterpriseProfiles = /* GraphQL */ `
  query SyncEnterpriseProfiles(
    $filter: ModelEnterpriseProfileFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEnterpriseProfiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        licenseCount
        membership {
          name
          status
          createdAt
          updatedAt
        }
        subscription {
          id
          provider
          providerSubID
          providerCustomerID
          status
          currencyCode
          trialStart
          trialEnd
          currentTermStart
          currentTermEnd
          nextBillingAt
          cancelledAt
          billingPeriod
          billingPeriodUnit
          resourceVersion
          createdAt
          updatedAt
        }
        courseContentVersion
        deletedAt
        numberOfEmployees
        location
        hiddenCourses
        scormCloudAppID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCourse = /* GraphQL */ `
  query GetCourse($id: ID!) {
    getCourse(id: $id) {
      id
      source
      sourceID
      locale
      courseName
      courseDescription
      starterPack {
        title
        slug
        items {
          resourceType
        }
      }
      active
      modules {
        id
        source
        sourceID
        locale
        moduleName
        moduleDescription
        tasks {
          id
          taskType
        }
        createdAt
        updatedAt
      }
      tags
      categories
      courseTileImage {
        title
        href
      }
      courseHeroBannerImage {
        title
        href
      }
      deletedAt
      type
      enterpriseID
      learningStandard
      accessType
      status
      uploadType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listCourses = /* GraphQL */ `
  query ListCourses(
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        source
        sourceID
        locale
        courseName
        courseDescription
        starterPack {
          title
          slug
        }
        active
        modules {
          id
          source
          sourceID
          locale
          moduleName
          moduleDescription
          createdAt
          updatedAt
        }
        tags
        categories
        courseTileImage {
          title
          href
        }
        courseHeroBannerImage {
          title
          href
        }
        deletedAt
        type
        enterpriseID
        learningStandard
        accessType
        status
        uploadType
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCourses = /* GraphQL */ `
  query SyncCourses(
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCourses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        source
        sourceID
        locale
        courseName
        courseDescription
        starterPack {
          title
          slug
        }
        active
        modules {
          id
          source
          sourceID
          locale
          moduleName
          moduleDescription
          createdAt
          updatedAt
        }
        tags
        categories
        courseTileImage {
          title
          href
        }
        courseHeroBannerImage {
          title
          href
        }
        deletedAt
        type
        enterpriseID
        learningStandard
        accessType
        status
        uploadType
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSource = /* GraphQL */ `
  query GetSource($id: ID!) {
    getSource(id: $id) {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listSources = /* GraphQL */ `
  query ListSources(
    $filter: ModelSourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSources(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSources = /* GraphQL */ `
  query SyncSources(
    $filter: ModelSourceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSources(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getReport = /* GraphQL */ `
  query GetReport($id: ID!) {
    getReport(id: $id) {
      id
      sourceReferenceID
      sourceID
      locale
      name
      heading
      subHeading
      startCTA
      explainer
      assessment {
        source
        title
        formId
      }
      skills {
        id
        label
        skillReport {
          title
          href
        }
        iconLink {
          title
          href
        }
        selectedIconLink {
          title
          href
        }
        subSkills {
          id
          label
          description
        }
      }
      deletedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      source {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const listReports = /* GraphQL */ `
  query ListReports(
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sourceReferenceID
        sourceID
        locale
        name
        heading
        subHeading
        startCTA
        explainer
        assessment {
          source
          title
          formId
        }
        skills {
          id
          label
        }
        deletedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        source {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncReports = /* GraphQL */ `
  query SyncReports(
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncReports(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        sourceReferenceID
        sourceID
        locale
        name
        heading
        subHeading
        startCTA
        explainer
        assessment {
          source
          title
          formId
        }
        skills {
          id
          label
        }
        deletedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        source {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getReportResult = /* GraphQL */ `
  query GetReportResult($id: ID!) {
    getReportResult(id: $id) {
      id
      reportID
      userID
      userSub
      formID
      responseID
      skillScores {
        skillID
        score
        maxScore
        subSkillScores {
          subSkillID
          score
          maxScore
          scoreKey
        }
      }
      deletedAt
      enterpriseID
      archived
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        sub
        name
        firstName
        lastName
        email
        enabled
        status
        roles
        lastActive
        deletedAt
        enterpriseID
        phoneNumber
        designation
        optin
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        groupMemberships {
          nextToken
          startedAt
        }
      }
      report {
        id
        sourceReferenceID
        sourceID
        locale
        name
        heading
        subHeading
        startCTA
        explainer
        assessment {
          source
          title
          formId
        }
        skills {
          id
          label
        }
        deletedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        source {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listReportResults = /* GraphQL */ `
  query ListReportResults(
    $filter: ModelReportResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportResults(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        reportID
        userID
        userSub
        formID
        responseID
        skillScores {
          skillID
          score
          maxScore
        }
        deletedAt
        enterpriseID
        archived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        user {
          id
          sub
          name
          firstName
          lastName
          email
          enabled
          status
          roles
          lastActive
          deletedAt
          enterpriseID
          phoneNumber
          designation
          optin
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        report {
          id
          sourceReferenceID
          sourceID
          locale
          name
          heading
          subHeading
          startCTA
          explainer
          deletedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const listReportResultsByUserSub = /* GraphQL */ `
  query ListReportResultsByUserSub(
    $userSub: String
    $sortDirection: ModelSortDirection
    $filter: ModelReportResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportResultsByUserSub(
      userSub: $userSub
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        reportID
        userID
        userSub
        formID
        responseID
        skillScores {
          skillID
          score
          maxScore
        }
        deletedAt
        enterpriseID
        archived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        user {
          id
          sub
          name
          firstName
          lastName
          email
          enabled
          status
          roles
          lastActive
          deletedAt
          enterpriseID
          phoneNumber
          designation
          optin
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        report {
          id
          sourceReferenceID
          sourceID
          locale
          name
          heading
          subHeading
          startCTA
          explainer
          deletedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const listReportResultsByEnterpriseID = /* GraphQL */ `
  query ListReportResultsByEnterpriseID(
    $enterpriseID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelReportResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportResultsByEnterpriseID(
      enterpriseID: $enterpriseID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        reportID
        userID
        userSub
        formID
        responseID
        skillScores {
          skillID
          score
          maxScore
        }
        deletedAt
        enterpriseID
        archived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        user {
          id
          sub
          name
          firstName
          lastName
          email
          enabled
          status
          roles
          lastActive
          deletedAt
          enterpriseID
          phoneNumber
          designation
          optin
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        report {
          id
          sourceReferenceID
          sourceID
          locale
          name
          heading
          subHeading
          startCTA
          explainer
          deletedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncReportResults = /* GraphQL */ `
  query SyncReportResults(
    $filter: ModelReportResultFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncReportResults(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        reportID
        userID
        userSub
        formID
        responseID
        skillScores {
          skillID
          score
          maxScore
        }
        deletedAt
        enterpriseID
        archived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        user {
          id
          sub
          name
          firstName
          lastName
          email
          enabled
          status
          roles
          lastActive
          deletedAt
          enterpriseID
          phoneNumber
          designation
          optin
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        report {
          id
          sourceReferenceID
          sourceID
          locale
          name
          heading
          subHeading
          startCTA
          explainer
          deletedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getReportSubmission = /* GraphQL */ `
  query GetReportSubmission($id: ID!) {
    getReportSubmission(id: $id) {
      id
      reportID
      userID
      userSub
      status
      deletedAt
      enterpriseID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listReportSubmissions = /* GraphQL */ `
  query ListReportSubmissions(
    $filter: ModelReportSubmissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportSubmissions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        reportID
        userID
        userSub
        status
        deletedAt
        enterpriseID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listReportSubmissionsByUserSub = /* GraphQL */ `
  query ListReportSubmissionsByUserSub(
    $userSub: String
    $sortDirection: ModelSortDirection
    $filter: ModelReportSubmissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportSubmissionsByUserSub(
      userSub: $userSub
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        reportID
        userID
        userSub
        status
        deletedAt
        enterpriseID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listReportSubmissionsByEnterpriseID = /* GraphQL */ `
  query ListReportSubmissionsByEnterpriseID(
    $enterpriseID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelReportSubmissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportSubmissionsByEnterpriseID(
      enterpriseID: $enterpriseID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        reportID
        userID
        userSub
        status
        deletedAt
        enterpriseID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncReportSubmissions = /* GraphQL */ `
  query SyncReportSubmissions(
    $filter: ModelReportSubmissionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncReportSubmissions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        reportID
        userID
        userSub
        status
        deletedAt
        enterpriseID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTypeformResponse = /* GraphQL */ `
  query GetTypeformResponse($id: ID!) {
    getTypeformResponse(id: $id) {
      id
      formID
      userID
      assessmentType
      payload
      deletedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listTypeformResponses = /* GraphQL */ `
  query ListTypeformResponses(
    $filter: ModelTypeformResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTypeformResponses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        formID
        userID
        assessmentType
        payload
        deletedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTypeformResponses = /* GraphQL */ `
  query SyncTypeformResponses(
    $filter: ModelTypeformResponseFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTypeformResponses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        formID
        userID
        assessmentType
        payload
        deletedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCourseRegistration = /* GraphQL */ `
  query GetCourseRegistration($id: ID!) {
    getCourseRegistration(id: $id) {
      id
      courseID
      userID
      userSub
      status
      deadline
      deletedAt
      enterpriseID
      assignedByUserID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listCourseRegistrations = /* GraphQL */ `
  query ListCourseRegistrations(
    $filter: ModelCourseRegistrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourseRegistrations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        courseID
        userID
        userSub
        status
        deadline
        deletedAt
        enterpriseID
        assignedByUserID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listCourseRegistrationsByUserSub = /* GraphQL */ `
  query ListCourseRegistrationsByUserSub(
    $userSub: String
    $sortDirection: ModelSortDirection
    $filter: ModelCourseRegistrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourseRegistrationsByUserSub(
      userSub: $userSub
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        courseID
        userID
        userSub
        status
        deadline
        deletedAt
        enterpriseID
        assignedByUserID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listCourseRegistrationsByEnterpriseID = /* GraphQL */ `
  query ListCourseRegistrationsByEnterpriseID(
    $enterpriseID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCourseRegistrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourseRegistrationsByEnterpriseID(
      enterpriseID: $enterpriseID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        courseID
        userID
        userSub
        status
        deadline
        deletedAt
        enterpriseID
        assignedByUserID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCourseRegistrations = /* GraphQL */ `
  query SyncCourseRegistrations(
    $filter: ModelCourseRegistrationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCourseRegistrations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        courseID
        userID
        userSub
        status
        deadline
        deletedAt
        enterpriseID
        assignedByUserID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCourseRegistrationActivity = /* GraphQL */ `
  query GetCourseRegistrationActivity($id: ID!) {
    getCourseRegistrationActivity(id: $id) {
      id
      courseRegistrationID
      userID
      userSub
      taskID
      taskType
      courseID
      status
      deletedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        sub
        name
        firstName
        lastName
        email
        enabled
        status
        roles
        lastActive
        deletedAt
        enterpriseID
        phoneNumber
        designation
        optin
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        groupMemberships {
          nextToken
          startedAt
        }
      }
      courseRegistration {
        id
        courseID
        userID
        userSub
        status
        deadline
        deletedAt
        enterpriseID
        assignedByUserID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const listCourseRegistrationActivities = /* GraphQL */ `
  query ListCourseRegistrationActivities(
    $filter: ModelCourseRegistrationActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourseRegistrationActivities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        courseRegistrationID
        userID
        userSub
        taskID
        taskType
        courseID
        status
        deletedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        user {
          id
          sub
          name
          firstName
          lastName
          email
          enabled
          status
          roles
          lastActive
          deletedAt
          enterpriseID
          phoneNumber
          designation
          optin
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        courseRegistration {
          id
          courseID
          userID
          userSub
          status
          deadline
          deletedAt
          enterpriseID
          assignedByUserID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const listCourseRegistrationActivitiesByUserSub = /* GraphQL */ `
  query ListCourseRegistrationActivitiesByUserSub(
    $userSub: String
    $sortDirection: ModelSortDirection
    $filter: ModelCourseRegistrationActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourseRegistrationActivitiesByUserSub(
      userSub: $userSub
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        courseRegistrationID
        userID
        userSub
        taskID
        taskType
        courseID
        status
        deletedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        user {
          id
          sub
          name
          firstName
          lastName
          email
          enabled
          status
          roles
          lastActive
          deletedAt
          enterpriseID
          phoneNumber
          designation
          optin
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        courseRegistration {
          id
          courseID
          userID
          userSub
          status
          deadline
          deletedAt
          enterpriseID
          assignedByUserID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCourseRegistrationActivities = /* GraphQL */ `
  query SyncCourseRegistrationActivities(
    $filter: ModelCourseRegistrationActivityFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCourseRegistrationActivities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        courseRegistrationID
        userID
        userSub
        taskID
        taskType
        courseID
        status
        deletedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        user {
          id
          sub
          name
          firstName
          lastName
          email
          enabled
          status
          roles
          lastActive
          deletedAt
          enterpriseID
          phoneNumber
          designation
          optin
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        courseRegistration {
          id
          courseID
          userID
          userSub
          status
          deadline
          deletedAt
          enterpriseID
          assignedByUserID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getAssignmentResponse = /* GraphQL */ `
  query GetAssignmentResponse($id: ID!) {
    getAssignmentResponse(id: $id) {
      id
      courseRegistrationID
      userID
      userSub
      taskID
      source
      formID
      responseID
      score
      totalScore
      deletedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listAssignmentResponses = /* GraphQL */ `
  query ListAssignmentResponses(
    $filter: ModelAssignmentResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssignmentResponses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        courseRegistrationID
        userID
        userSub
        taskID
        source
        formID
        responseID
        score
        totalScore
        deletedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listAssignmentResponsesByUserSub = /* GraphQL */ `
  query ListAssignmentResponsesByUserSub(
    $userSub: String
    $sortDirection: ModelSortDirection
    $filter: ModelAssignmentResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssignmentResponsesByUserSub(
      userSub: $userSub
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        courseRegistrationID
        userID
        userSub
        taskID
        source
        formID
        responseID
        score
        totalScore
        deletedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAssignmentResponses = /* GraphQL */ `
  query SyncAssignmentResponses(
    $filter: ModelAssignmentResponseFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAssignmentResponses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        courseRegistrationID
        userID
        userSub
        taskID
        source
        formID
        responseID
        score
        totalScore
        deletedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      userID
      userSub
      title
      message
      read
      deletedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        sub
        name
        firstName
        lastName
        email
        enabled
        status
        roles
        lastActive
        deletedAt
        enterpriseID
        phoneNumber
        designation
        optin
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        groupMemberships {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        userSub
        title
        message
        read
        deletedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        user {
          id
          sub
          name
          firstName
          lastName
          email
          enabled
          status
          roles
          lastActive
          deletedAt
          enterpriseID
          phoneNumber
          designation
          optin
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const listNotificationsByUserSub = /* GraphQL */ `
  query ListNotificationsByUserSub(
    $userSub: String
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotificationsByUserSub(
      userSub: $userSub
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        userSub
        title
        message
        read
        deletedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        user {
          id
          sub
          name
          firstName
          lastName
          email
          enabled
          status
          roles
          lastActive
          deletedAt
          enterpriseID
          phoneNumber
          designation
          optin
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncNotifications = /* GraphQL */ `
  query SyncNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userID
        userSub
        title
        message
        read
        deletedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        user {
          id
          sub
          name
          firstName
          lastName
          email
          enabled
          status
          roles
          lastActive
          deletedAt
          enterpriseID
          phoneNumber
          designation
          optin
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getInvitation = /* GraphQL */ `
  query GetInvitation($email: String!, $inviterEnterpriseID: ID!) {
    getInvitation(email: $email, inviterEnterpriseID: $inviterEnterpriseID) {
      id
      email
      status
      inviterID
      inviterEnterpriseID
      inviteeID
      roles
      groupID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      inviter {
        id
        sub
        name
        firstName
        lastName
        email
        enabled
        status
        roles
        lastActive
        deletedAt
        enterpriseID
        phoneNumber
        designation
        optin
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        groupMemberships {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const listInvitations = /* GraphQL */ `
  query ListInvitations(
    $email: String
    $inviterEnterpriseID: ModelIDKeyConditionInput
    $filter: ModelInvitationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInvitations(
      email: $email
      inviterEnterpriseID: $inviterEnterpriseID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        email
        status
        inviterID
        inviterEnterpriseID
        inviteeID
        roles
        groupID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        inviter {
          id
          sub
          name
          firstName
          lastName
          email
          enabled
          status
          roles
          lastActive
          deletedAt
          enterpriseID
          phoneNumber
          designation
          optin
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getInvitationByID = /* GraphQL */ `
  query GetInvitationByID(
    $id: ID
    $sortDirection: ModelSortDirection
    $filter: ModelInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getInvitationByID(
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        status
        inviterID
        inviterEnterpriseID
        inviteeID
        roles
        groupID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        inviter {
          id
          sub
          name
          firstName
          lastName
          email
          enabled
          status
          roles
          lastActive
          deletedAt
          enterpriseID
          phoneNumber
          designation
          optin
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const listInvitationsByEmail = /* GraphQL */ `
  query ListInvitationsByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvitationsByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        status
        inviterID
        inviterEnterpriseID
        inviteeID
        roles
        groupID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        inviter {
          id
          sub
          name
          firstName
          lastName
          email
          enabled
          status
          roles
          lastActive
          deletedAt
          enterpriseID
          phoneNumber
          designation
          optin
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncInvitations = /* GraphQL */ `
  query SyncInvitations(
    $filter: ModelInvitationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInvitations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        email
        status
        inviterID
        inviterEnterpriseID
        inviteeID
        roles
        groupID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        inviter {
          id
          sub
          name
          firstName
          lastName
          email
          enabled
          status
          roles
          lastActive
          deletedAt
          enterpriseID
          phoneNumber
          designation
          optin
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
