/* eslint-disable max-lines */
const listCourses = /* GraphQL */ `
  query ListCourses(
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        source
        sourceID
        locale
        courseName
        courseDescription
        starterPack {
          title
          slug
          items {
            resourceType
            documentResource {
              source
              link {
                title
                href
              }
            }
            videoResource {
              source
              name
              uri
            }
          }
        }
        active
        modules {
          id
          source
          sourceID
          locale
          moduleName
          moduleDescription
          tasks {
            id
            taskType
            videoTask {
              lessonName
              lessonDescription
              lessonVideo {
                source
                name
                uri
              }
              lessonResources {
                resourceType
                documentResource {
                  source
                  link {
                    title
                    href
                  }
                }
                videoResource {
                  source
                  name
                  uri
                }
              }
            }
            assignmentTask {
              source
              title
              formId
            }
            finalAssignmentTask {
              source
              title
              formId
            }
          }
          createdAt
          updatedAt
        }
        tags
        categories
        courseTileImage {
          title
          href
        }
        courseHeroBannerImage {
          title
          href
        }
        deletedAt
        type
        enterpriseID
        learningStandard
        accessType
        status
        uploadType
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;

const adminListReportResultsByEnterpriseID = /* GraphQL */ `
  query AdminListReportResultsByEnterpriseID(
    $enterpriseID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelReportResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminListReportResultsByEnterpriseID(
      enterpriseID: $enterpriseID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        reportID
        updatedAt
        archived
        skillScores {
          skillID
          score
          maxScore
        }
        deletedAt
        enterpriseID
        user {
          id
          name
          email
        }
      }
      nextToken
    }
  }
`;

const listPrivateCoursesByEnterpriseID = /* GraphQL */ `
  query ListPrivateCoursesByEnterpriseID(
    $enterpriseID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrivateCoursesByEnterpriseID(
      enterpriseID: $enterpriseID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        source
        sourceID
        locale
        courseName
        courseDescription
        starterPack {
          title
          slug
          items {
            resourceType
            documentResource {
              source
              link {
                title
                href
              }
            }
            videoResource {
              source
              name
              uri
            }
          }
        }
        active
        modules {
          id
          source
          sourceID
          locale
          moduleName
          moduleDescription
          tasks {
            id
            taskType
            videoTask {
              lessonName
              lessonDescription
              lessonVideo {
                source
                name
                uri
              }
              lessonResources {
                resourceType
                documentResource {
                  source
                  link {
                    title
                    href
                  }
                }
                videoResource {
                  source
                  name
                  uri
                }
              }
            }
            assignmentTask {
              source
              title
              formId
            }
            finalAssignmentTask {
              source
              title
              formId
            }
          }
          createdAt
          updatedAt
        }
        tags
        categories
        courseTileImage {
          title
          href
        }
        courseHeroBannerImage {
          title
          href
        }
        deletedAt
        type
        enterpriseID
        learningStandard
        accessType
        status
        uploadType
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;

const getCourse = /* GraphQL */ `
  query GetCourse($id: ID!) {
    getCourse(id: $id) {
      id
      source
      sourceID
      locale
      courseName
      courseDescription
      starterPack {
        title
        slug
        items {
          resourceType
          documentResource {
            source
            link {
              title
              href
            }
          }
          videoResource {
            source
            name
            uri
          }
        }
      }
      active
      modules {
        id
        source
        sourceID
        locale
        moduleName
        moduleDescription
        tasks {
          id
          taskType
          videoTask {
            lessonName
            lessonDescription
            lessonVideo {
              source
              name
              uri
            }
            lessonResources {
              resourceType
              documentResource {
                source
                link {
                  title
                  href
                }
              }
              videoResource {
                source
                name
                uri
              }
            }
          }
          assignmentTask {
            source
            title
            formId
          }
          finalAssignmentTask {
            source
            title
            formId
          }
        }
        createdAt
        updatedAt
      }
      tags
      categories
      courseTileImage {
        title
        href
      }
      courseHeroBannerImage {
        title
        href
      }
      deletedAt
      type
      enterpriseID
      learningStandard
      accessType
      status
      uploadType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;

export {
  listCourses,
  adminListReportResultsByEnterpriseID,
  listPrivateCoursesByEnterpriseID,
  getCourse,
};
