/* eslint-disable no-underscore-dangle */
/* eslint-disable max-lines */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-param-reassign */
import { API } from 'aws-amplify';

import * as GraphQLQueries from '../../graphql/queries';
import * as GraphQLMutations from '../../graphql/mutations';
import { CourseRegistrationStatus } from '../../models';
import { listPrivateCoursesByEnterpriseID, getCourse } from '../../custom-graphql/queries';

const getUserBySub = async (sub) => {
  const returnData = null;
  let nextToken;
  do {
    const queryfilter = {
      and: [
        { sub: { eq: sub } },
        { deletedAt: { attributeExists: false } },
      ],
    };
    const options = {
      query: GraphQLQueries.listUsers,
      variables: {
        filter: queryfilter,
      },
    };
    if (nextToken) {
      options.variables.nextToken = nextToken;
    }
    // eslint-disable-next-line no-await-in-loop
    const result = await API.graphql(options);
    if (result?.data?.listUsers?.items?.length === 1) {
      const [item] = result?.data?.listUsers?.items;
      return { ...item };
    }
    nextToken = result?.data?.listUsers?.nextToken;
  } while (nextToken);
  return returnData;
};

const getGroup = async (id) => {
  const returnData = null;
  let nextToken;
  do {
    const queryfilter = {
      and: [
        { id: { eq: id } },
        { deletedAt: { attributeExists: false } },
      ],
    };
    const options = {
      query: GraphQLQueries.listGroups,
      variables: {
        filter: queryfilter,
      },
    };
    if (nextToken) {
      options.variables.nextToken = nextToken;
    }
    // eslint-disable-next-line no-await-in-loop
    const result = await API.graphql(options);
    if (result?.data?.listGroups?.items?.length === 1) {
      const [item] = result?.data?.listGroups?.items;
      return { ...item };
    }
    nextToken = result?.data?.listGroups?.nextToken;
  } while (nextToken);
  return returnData;
};

const getGroupByName = async (name) => {
  const returnData = null;
  let nextToken;
  do {
    const queryfilter = {
      and: [
        { name: { eq: name } },
        { deletedAt: { attributeExists: false } },
      ],
    };
    const options = {
      query: GraphQLQueries.listGroups,
      variables: {
        filter: queryfilter,
      },
    };
    if (nextToken) {
      options.variables.nextToken = nextToken;
    }
    // eslint-disable-next-line no-await-in-loop
    const result = await API.graphql(options);
    if (result?.data?.listGroups.items.length === 1) {
      const [item] = result.data.listGroups.items;
      return { ...item };
    }
    nextToken = result?.data?.listGroups?.nextToken;
  } while (nextToken);
  return returnData;
};

const getGroupMember = async (groupID, memberID) => {
  const returnData = null;
  let nextToken;
  do {
    const queryfilter = {
      and: [
        { groupID: { eq: groupID } },
        { memberID: { eq: memberID } },
        { deletedAt: { attributeExists: false } },
      ],
    };
    const options = {
      query: GraphQLQueries.adminListGroupMembers,
      variables: {
        filter: queryfilter,
      },
    };
    if (nextToken) {
      options.variables.nextToken = nextToken;
    }
    // eslint-disable-next-line no-await-in-loop
    const result = await API.graphql(options);
    if (result?.data?.adminListGroupMembers.items.length === 1) {
      const [item] = result?.data?.adminListGroupMembers?.items;
      return { ...item };
    }
    nextToken = result?.data?.adminListGroupMembers?.nextToken;
  } while (nextToken);
  return returnData;
};

const createGroupMember = async (groupID, memberID, isLearner, isManager, userSub) => {
  const result = await API.graphql({
    query: GraphQLMutations.createGroupMember,
    variables: {
      input: {
        groupID,
        memberID,
        isLearner,
        isManager,
        userSub,
      },
    },
  });
  return result.data.createGroupMember; // todo handle error cases
};

const createGroup = async (name, enterpriseID) => {
  const result = await API.graphql({
    query: GraphQLMutations.adminCreateGroup,
    variables: {
      input: {
        name,
        enterpriseID,
      },
    },
  });

  return result.data.adminCreateGroup;
};

const updateGroupMember = async (id, _version, isLearner, isManager, deleteGroupMember) => {
  const deletedAt = new Date();
  const result = await API.graphql({
    query: GraphQLMutations.adminUpdateGroupMember,
    variables: {
      input: {
        id,
        _version,
        isLearner,
        isManager,
        ...(deleteGroupMember && { deletedAt }),
      },
    },
  });
  return result.data.adminUpdateGroupMember; // todo handle error cases
};

const updateCourseRegistration = async (id, courseID, _version, status) => {
  const updateCourseReg = await API.graphql({
    query: GraphQLMutations.updateCourseRegistration,
    variables: {
      input: {
        _version,
        courseID,
        id,
        status,
      },
    },
  });
  return updateCourseReg;
};

const getUsersByEnterpriseID = async (enterpriseID) => {
  const usersList = [];
  let nextToken;
  do {
    const options = {
      query: GraphQLQueries.adminListUsersByEnterpriseID,
      variables: {
        enterpriseID,
      },
    };

    if (nextToken) {
      options.variables.nextToken = nextToken;
    }

    const result = await API.graphql(options);

    const usersWithKeyProperty = result?.data?.adminListUsersByEnterpriseID?.items.map((user) => {
      user.key = user.id;
      return user;
    });

    usersList?.push(...usersWithKeyProperty);

    nextToken = result?.data?.adminListUsersByEnterpriseID?.nextToken;
  } while (nextToken);

  return usersList;
};

const getGroupMembersByGroupId = async (id) => {
  const groupMembers = [];
  let nextToken;
  do {
    const options = {
      query: GraphQLQueries.adminGetGroup,
      variables: {
        id,
      },
    };

    if (nextToken) {
      options.variables.nextToken = nextToken;
    }

    const result = await API.graphql(options);

    groupMembers.push(...result?.data?.adminGetGroup?.members?.items);

    nextToken = result?.data?.adminGetGroup?.members?.nextToken;
  } while (nextToken);

  return groupMembers;
};

const getGroupsByEnterpriseID = async (enterpriseID) => {
  let nextToken;
  const formattedData = [];
  do {
    const options = {
      query: GraphQLQueries.adminListGroupsByEnterpriseID,
      variables: {
        enterpriseID,
      },
    };

    if (nextToken) {
      options.variables.nextToken = nextToken;
    }

    const result = await API.graphql(options);

    const groupDetails = result?.data?.adminListGroupsByEnterpriseID?.items;
    if (groupDetails) {
      for (let i = 0; i < groupDetails?.length; i += 1) {
        formattedData.push({
          key: groupDetails[i]?.id,
          id: groupDetails[i]?.id,
          groupName: groupDetails[i]?.name,
          dateCreated: groupDetails[i]?.createdAt,
          memberCount: groupDetails[i]?.membersCount ? groupDetails[i].membersCount : 0,
          version: groupDetails[i]?._version,
          deletedAt: groupDetails[i]?.deletedAt,
        });
      }
    }

    nextToken = result?.data?.adminListGroupsByEnterpriseID?.nextToken;
  } while (nextToken);

  return formattedData;
};

const addUsersToGroup = async (groupID, memberID, userSub, isLearner, isManager) => {
  const result = await API.graphql({
    query: GraphQLMutations.adminCreateGroupMember,
    variables: {
      input: {
        groupID,
        memberID,
        userSub,
        isLearner,
        isManager,
      },
    },
  });

  return result;
};

const deleteGroupMember = async (userId, version) => {
  const deletedAt = new Date();

  const result = await API.graphql({
    query: GraphQLMutations.adminDeleteGroupMember,
    variables: {
      input: {
        id: userId,
        _version: version,
        deletedAt,
      },
    },
  });

  return result;
};

const updateGroupMemberRole = async (id, _version, isLearner, isManager) => {
  const result = await API.graphql({
    query: GraphQLMutations.adminUpdateGroupMember,
    variables: {
      input: {
        id,
        _version,
        isLearner,
        isManager,
      },
    },
  });

  return result;
};

const adminUpdateUser = async (id, roles, _version, enabled, status) => {
  const result = await API.graphql({
    query: GraphQLMutations.adminUpdateUser,
    variables: {
      input: {
        id,
        roles,
        _version,
        enabled,
        status,
      },
    },
  });

  return result;
};

const deleteGroup = async (id) => {
  const deletedAt = new Date();

  const result = await API.graphql({
    query: GraphQLMutations.adminUpdateGroup,
    variables: {
      input: {
        id,
        deletedAt,
      },
    },
  });

  return result;
};

const changeGroupName = async (id, name, _version) => {
  const result = await API.graphql({
    query: GraphQLMutations.adminUpdateGroup,
    variables: {
      input: {
        id,
        name,
        _version,
      },
    },
  });

  return result;
};

const getEnterpriseProfile = async (id) => {
  const result = await API.graphql({
    query: GraphQLQueries.getEnterpriseProfile,
    variables: {
      id,
    },
  });

  return result.data.getEnterpriseProfile;
};

const getCourseEnrolledUsers = async (enterpriseID, courseID) => {
  let nextToken;
  const allEnrolledUsers = [];

  do {
    const queryfilter = {
      and: [
        { courseID: { eq: courseID } },
      ],
    };

    const options = {
      query: GraphQLQueries.adminListCourseRegistrationsByEnterpriseID,
      variables: {
        enterpriseID,
        filter: queryfilter,
      },
    };

    if (nextToken) {
      options.variables.nextToken = nextToken;
    }

    const result = await API.graphql(options);

    allEnrolledUsers.push(...result?.data?.adminListCourseRegistrationsByEnterpriseID?.items);

    nextToken = result?.data?.adminListCourseRegistrationsByEnterpriseID?.nextToken;
  } while (nextToken);

  return allEnrolledUsers;
};

const adminCreateCourseRegistration = async (
  userID, courseID, enterpriseID, userSub, assignedByUserID,
) => {
  const result = await API.graphql({
    query: GraphQLMutations.adminCreateCourseRegistration,
    variables: {
      input: {
        userID,
        courseID,
        enterpriseID,
        status: CourseRegistrationStatus.ASSIGNED,
        userSub,
        assignedByUserID,
      },
    },
  });

  return result?.data?.adminCreateCourseRegistration;
};

const requestReportSubmission = async (enterpriseID, userID, reportID) => {
  const result = await API.graphql({
    query: GraphQLMutations.adminRequestReportSubmission,
    variables: {
      input: {
        enterpriseID,
        userID,
        reportID,
      },
    },
  });

  return result;
};

const updateCourseRegistrationActivity = async (
  id,
  courseRegistrationID,
  _version,
  taskID,
  status,
) => {
  const result = await API.graphql({
    query: GraphQLMutations.updateCourseRegistrationActivity,
    variables: {
      input: {
        id,
        courseRegistrationID,
        _version,
        taskID,
        status,
      },
    },
  });

  return result;
};

const listPrivateCoursesByEnterpriseId = async (enterpriseID, courseID = '') => {
  let nextToken;
  let queryfilter;
  const allPrivateCourses = [];

  do {
    if (courseID) {
      queryfilter = {
        and: [
          { id: { eq: courseID } },
        ],
      };
    }

    const options = {
      query: listPrivateCoursesByEnterpriseID,
      variables: {
        enterpriseID,
        ...(queryfilter && { filter: queryfilter }),
      },
    };

    if (nextToken) {
      options.variables.nextToken = nextToken;
    }

    const result = await API.graphql(options);

    allPrivateCourses.push(...result?.data?.listPrivateCoursesByEnterpriseID?.items);

    nextToken = result?.data?.listPrivateCoursesByEnterpriseID?.nextToken;
  } while (nextToken);

  return allPrivateCourses;
};

const getCourseById = async (courseId) => {
  const options = {
    query: getCourse,
    variables: {
      id: courseId,
    },
  };
  const result = await API.graphql(options);
  return result?.data?.getCourse;
};

const adminUpdateCourse = async (payload) => {
  const options = {
    query: GraphQLMutations.adminUpdateCourse,
    variables: {
      input: payload,
    },
  };
  const result = await API.graphql(options);
  return result?.data?.adminUpdateCourse;
};

export {
  getUserBySub,
  getGroup,
  getGroupByName,
  getGroupMember,
  updateGroupMember,
  createGroupMember,
  createGroup,
  updateCourseRegistration,
  getUsersByEnterpriseID,
  getGroupsByEnterpriseID,
  getGroupMembersByGroupId,
  addUsersToGroup,
  deleteGroupMember,
  updateGroupMemberRole,
  adminUpdateUser,
  deleteGroup,
  changeGroupName,
  getEnterpriseProfile,
  getCourseEnrolledUsers,
  adminCreateCourseRegistration,
  requestReportSubmission,
  updateCourseRegistrationActivity,
  listPrivateCoursesByEnterpriseId,
  getCourseById,
  adminUpdateCourse,
};
