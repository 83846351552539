/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const adminUpdateUser = /* GraphQL */ `
  mutation AdminUpdateUser($input: adminUpdateUserInput!) {
    adminUpdateUser(input: $input) {
      id
      sub
      name
      firstName
      lastName
      email
      enabled
      status
      roles
      lastActive
      deletedAt
      enterpriseID
      phoneNumber
      designation
      optin
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      groupMemberships {
        items {
          id
          memberID
          groupID
          isManager
          isLearner
          userSub
          deletedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const adminCreateGroup = /* GraphQL */ `
  mutation AdminCreateGroup($input: adminCreateGroupInput!) {
    adminCreateGroup(input: $input) {
      id
      name
      maxMembers
      deletedAt
      enterpriseID
      membersCount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      members {
        items {
          id
          memberID
          groupID
          isManager
          isLearner
          userSub
          deletedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const adminUpdateGroup = /* GraphQL */ `
  mutation AdminUpdateGroup($input: adminUpdateGroupInput!) {
    adminUpdateGroup(input: $input) {
      id
      name
      maxMembers
      deletedAt
      enterpriseID
      membersCount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      members {
        items {
          id
          memberID
          groupID
          isManager
          isLearner
          userSub
          deletedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const adminCreateGroupMember = /* GraphQL */ `
  mutation AdminCreateGroupMember($input: adminCreateGroupMemberInput!) {
    adminCreateGroupMember(input: $input) {
      id
      memberID
      groupID
      isManager
      isLearner
      userSub
      deletedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      member {
        id
        sub
        name
        firstName
        lastName
        email
        enabled
        status
        roles
        lastActive
        deletedAt
        enterpriseID
        phoneNumber
        designation
        optin
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        groupMemberships {
          nextToken
          startedAt
        }
      }
      group {
        id
        name
        maxMembers
        deletedAt
        enterpriseID
        membersCount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        members {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const adminUpdateGroupMember = /* GraphQL */ `
  mutation AdminUpdateGroupMember($input: adminUpdateGroupMemberInput!) {
    adminUpdateGroupMember(input: $input) {
      id
      memberID
      groupID
      isManager
      isLearner
      userSub
      deletedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      member {
        id
        sub
        name
        firstName
        lastName
        email
        enabled
        status
        roles
        lastActive
        deletedAt
        enterpriseID
        phoneNumber
        designation
        optin
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        groupMemberships {
          nextToken
          startedAt
        }
      }
      group {
        id
        name
        maxMembers
        deletedAt
        enterpriseID
        membersCount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        members {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const adminCreateCourseRegistration = /* GraphQL */ `
  mutation AdminCreateCourseRegistration(
    $input: adminCreateCourseRegistrationInput!
  ) {
    adminCreateCourseRegistration(input: $input) {
      id
      courseID
      userID
      userSub
      status
      deadline
      deletedAt
      enterpriseID
      assignedByUserID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const adminRequestReportSubmission = /* GraphQL */ `
  mutation AdminRequestReportSubmission($input: RequestReportSubmission) {
    adminRequestReportSubmission(input: $input)
  }
`;
export const adminResetReportSubmission = /* GraphQL */ `
  mutation AdminResetReportSubmission($input: ResetReportSubmission) {
    adminResetReportSubmission(input: $input)
  }
`;
export const adminCreateCourse = /* GraphQL */ `
  mutation AdminCreateCourse(
    $input: CreateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    adminCreateCourse(input: $input, condition: $condition) {
      id
      source
      sourceID
      locale
      courseName
      courseDescription
      starterPack {
        title
        slug
        items {
          resourceType
        }
      }
      active
      modules {
        id
        source
        sourceID
        locale
        moduleName
        moduleDescription
        tasks {
          id
          taskType
        }
        createdAt
        updatedAt
      }
      tags
      categories
      courseTileImage {
        title
        href
      }
      courseHeroBannerImage {
        title
        href
      }
      deletedAt
      type
      enterpriseID
      learningStandard
      accessType
      status
      uploadType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const adminUpdateCourse = /* GraphQL */ `
  mutation AdminUpdateCourse($input: AdminUpdateCourseInput!) {
    adminUpdateCourse(input: $input) {
      id
      source
      sourceID
      locale
      courseName
      courseDescription
      starterPack {
        title
        slug
        items {
          resourceType
        }
      }
      active
      modules {
        id
        source
        sourceID
        locale
        moduleName
        moduleDescription
        tasks {
          id
          taskType
        }
        createdAt
        updatedAt
      }
      tags
      categories
      courseTileImage {
        title
        href
      }
      courseHeroBannerImage {
        title
        href
      }
      deletedAt
      type
      enterpriseID
      learningStandard
      accessType
      status
      uploadType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      sub
      name
      firstName
      lastName
      email
      enabled
      status
      roles
      lastActive
      deletedAt
      enterpriseID
      phoneNumber
      designation
      optin
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      groupMemberships {
        items {
          id
          memberID
          groupID
          isManager
          isLearner
          userSub
          deletedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      sub
      name
      firstName
      lastName
      email
      enabled
      status
      roles
      lastActive
      deletedAt
      enterpriseID
      phoneNumber
      designation
      optin
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      groupMemberships {
        items {
          id
          memberID
          groupID
          isManager
          isLearner
          userSub
          deletedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      sub
      name
      firstName
      lastName
      email
      enabled
      status
      roles
      lastActive
      deletedAt
      enterpriseID
      phoneNumber
      designation
      optin
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      groupMemberships {
        items {
          id
          memberID
          groupID
          isManager
          isLearner
          userSub
          deletedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $input: CreateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    createGroup(input: $input, condition: $condition) {
      id
      name
      maxMembers
      deletedAt
      enterpriseID
      membersCount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      members {
        items {
          id
          memberID
          groupID
          isManager
          isLearner
          userSub
          deletedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      id
      name
      maxMembers
      deletedAt
      enterpriseID
      membersCount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      members {
        items {
          id
          memberID
          groupID
          isManager
          isLearner
          userSub
          deletedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $input: UpdateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      id
      name
      maxMembers
      deletedAt
      enterpriseID
      membersCount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      members {
        items {
          id
          memberID
          groupID
          isManager
          isLearner
          userSub
          deletedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createGroupMember = /* GraphQL */ `
  mutation CreateGroupMember(
    $input: CreateGroupMemberInput!
    $condition: ModelGroupMemberConditionInput
  ) {
    createGroupMember(input: $input, condition: $condition) {
      id
      memberID
      groupID
      isManager
      isLearner
      userSub
      deletedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      member {
        id
        sub
        name
        firstName
        lastName
        email
        enabled
        status
        roles
        lastActive
        deletedAt
        enterpriseID
        phoneNumber
        designation
        optin
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        groupMemberships {
          nextToken
          startedAt
        }
      }
      group {
        id
        name
        maxMembers
        deletedAt
        enterpriseID
        membersCount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        members {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteGroupMember = /* GraphQL */ `
  mutation DeleteGroupMember(
    $input: DeleteGroupMemberInput!
    $condition: ModelGroupMemberConditionInput
  ) {
    deleteGroupMember(input: $input, condition: $condition) {
      id
      memberID
      groupID
      isManager
      isLearner
      userSub
      deletedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      member {
        id
        sub
        name
        firstName
        lastName
        email
        enabled
        status
        roles
        lastActive
        deletedAt
        enterpriseID
        phoneNumber
        designation
        optin
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        groupMemberships {
          nextToken
          startedAt
        }
      }
      group {
        id
        name
        maxMembers
        deletedAt
        enterpriseID
        membersCount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        members {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateGroupMember = /* GraphQL */ `
  mutation UpdateGroupMember(
    $input: UpdateGroupMemberInput!
    $condition: ModelGroupMemberConditionInput
  ) {
    updateGroupMember(input: $input, condition: $condition) {
      id
      memberID
      groupID
      isManager
      isLearner
      userSub
      deletedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      member {
        id
        sub
        name
        firstName
        lastName
        email
        enabled
        status
        roles
        lastActive
        deletedAt
        enterpriseID
        phoneNumber
        designation
        optin
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        groupMemberships {
          nextToken
          startedAt
        }
      }
      group {
        id
        name
        maxMembers
        deletedAt
        enterpriseID
        membersCount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        members {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createEnterpriseProfile = /* GraphQL */ `
  mutation CreateEnterpriseProfile(
    $input: CreateEnterpriseProfileInput!
    $condition: ModelEnterpriseProfileConditionInput
  ) {
    createEnterpriseProfile(input: $input, condition: $condition) {
      id
      name
      licenseCount
      membership {
        name
        status
        createdAt
        updatedAt
      }
      subscription {
        id
        provider
        providerSubID
        providerCustomerID
        status
        entitlements {
          featureSlug
          featureType
          featureUnit
          value
        }
        currencyCode
        trialStart
        trialEnd
        currentTermStart
        currentTermEnd
        nextBillingAt
        cancelledAt
        items {
          itemPriceId
          itemType
          quantity
          unitPrice
          amount
          freeQuantity
          name
        }
        billingPeriod
        billingPeriodUnit
        resourceVersion
        createdAt
        updatedAt
      }
      courseContentVersion
      deletedAt
      numberOfEmployees
      location
      hiddenCourses
      scormCloudAppID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateEnterpriseProfile = /* GraphQL */ `
  mutation UpdateEnterpriseProfile(
    $input: UpdateEnterpriseProfileInput!
    $condition: ModelEnterpriseProfileConditionInput
  ) {
    updateEnterpriseProfile(input: $input, condition: $condition) {
      id
      name
      licenseCount
      membership {
        name
        status
        createdAt
        updatedAt
      }
      subscription {
        id
        provider
        providerSubID
        providerCustomerID
        status
        entitlements {
          featureSlug
          featureType
          featureUnit
          value
        }
        currencyCode
        trialStart
        trialEnd
        currentTermStart
        currentTermEnd
        nextBillingAt
        cancelledAt
        items {
          itemPriceId
          itemType
          quantity
          unitPrice
          amount
          freeQuantity
          name
        }
        billingPeriod
        billingPeriodUnit
        resourceVersion
        createdAt
        updatedAt
      }
      courseContentVersion
      deletedAt
      numberOfEmployees
      location
      hiddenCourses
      scormCloudAppID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteEnterpriseProfile = /* GraphQL */ `
  mutation DeleteEnterpriseProfile(
    $input: DeleteEnterpriseProfileInput!
    $condition: ModelEnterpriseProfileConditionInput
  ) {
    deleteEnterpriseProfile(input: $input, condition: $condition) {
      id
      name
      licenseCount
      membership {
        name
        status
        createdAt
        updatedAt
      }
      subscription {
        id
        provider
        providerSubID
        providerCustomerID
        status
        entitlements {
          featureSlug
          featureType
          featureUnit
          value
        }
        currencyCode
        trialStart
        trialEnd
        currentTermStart
        currentTermEnd
        nextBillingAt
        cancelledAt
        items {
          itemPriceId
          itemType
          quantity
          unitPrice
          amount
          freeQuantity
          name
        }
        billingPeriod
        billingPeriodUnit
        resourceVersion
        createdAt
        updatedAt
      }
      courseContentVersion
      deletedAt
      numberOfEmployees
      location
      hiddenCourses
      scormCloudAppID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createCourse = /* GraphQL */ `
  mutation CreateCourse(
    $input: CreateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    createCourse(input: $input, condition: $condition) {
      id
      source
      sourceID
      locale
      courseName
      courseDescription
      starterPack {
        title
        slug
        items {
          resourceType
        }
      }
      active
      modules {
        id
        source
        sourceID
        locale
        moduleName
        moduleDescription
        tasks {
          id
          taskType
        }
        createdAt
        updatedAt
      }
      tags
      categories
      courseTileImage {
        title
        href
      }
      courseHeroBannerImage {
        title
        href
      }
      deletedAt
      type
      enterpriseID
      learningStandard
      accessType
      status
      uploadType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateCourse = /* GraphQL */ `
  mutation UpdateCourse(
    $input: UpdateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    updateCourse(input: $input, condition: $condition) {
      id
      source
      sourceID
      locale
      courseName
      courseDescription
      starterPack {
        title
        slug
        items {
          resourceType
        }
      }
      active
      modules {
        id
        source
        sourceID
        locale
        moduleName
        moduleDescription
        tasks {
          id
          taskType
        }
        createdAt
        updatedAt
      }
      tags
      categories
      courseTileImage {
        title
        href
      }
      courseHeroBannerImage {
        title
        href
      }
      deletedAt
      type
      enterpriseID
      learningStandard
      accessType
      status
      uploadType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteCourse = /* GraphQL */ `
  mutation DeleteCourse(
    $input: DeleteCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    deleteCourse(input: $input, condition: $condition) {
      id
      source
      sourceID
      locale
      courseName
      courseDescription
      starterPack {
        title
        slug
        items {
          resourceType
        }
      }
      active
      modules {
        id
        source
        sourceID
        locale
        moduleName
        moduleDescription
        tasks {
          id
          taskType
        }
        createdAt
        updatedAt
      }
      tags
      categories
      courseTileImage {
        title
        href
      }
      courseHeroBannerImage {
        title
        href
      }
      deletedAt
      type
      enterpriseID
      learningStandard
      accessType
      status
      uploadType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createSource = /* GraphQL */ `
  mutation CreateSource(
    $input: CreateSourceInput!
    $condition: ModelSourceConditionInput
  ) {
    createSource(input: $input, condition: $condition) {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateSource = /* GraphQL */ `
  mutation UpdateSource(
    $input: UpdateSourceInput!
    $condition: ModelSourceConditionInput
  ) {
    updateSource(input: $input, condition: $condition) {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteSource = /* GraphQL */ `
  mutation DeleteSource(
    $input: DeleteSourceInput!
    $condition: ModelSourceConditionInput
  ) {
    deleteSource(input: $input, condition: $condition) {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createReport = /* GraphQL */ `
  mutation CreateReport(
    $input: CreateReportInput!
    $condition: ModelReportConditionInput
  ) {
    createReport(input: $input, condition: $condition) {
      id
      sourceReferenceID
      sourceID
      locale
      name
      heading
      subHeading
      startCTA
      explainer
      assessment {
        source
        title
        formId
      }
      skills {
        id
        label
        skillReport {
          title
          href
        }
        iconLink {
          title
          href
        }
        selectedIconLink {
          title
          href
        }
        subSkills {
          id
          label
          description
        }
      }
      deletedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      source {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateReport = /* GraphQL */ `
  mutation UpdateReport(
    $input: UpdateReportInput!
    $condition: ModelReportConditionInput
  ) {
    updateReport(input: $input, condition: $condition) {
      id
      sourceReferenceID
      sourceID
      locale
      name
      heading
      subHeading
      startCTA
      explainer
      assessment {
        source
        title
        formId
      }
      skills {
        id
        label
        skillReport {
          title
          href
        }
        iconLink {
          title
          href
        }
        selectedIconLink {
          title
          href
        }
        subSkills {
          id
          label
          description
        }
      }
      deletedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      source {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteReport = /* GraphQL */ `
  mutation DeleteReport(
    $input: DeleteReportInput!
    $condition: ModelReportConditionInput
  ) {
    deleteReport(input: $input, condition: $condition) {
      id
      sourceReferenceID
      sourceID
      locale
      name
      heading
      subHeading
      startCTA
      explainer
      assessment {
        source
        title
        formId
      }
      skills {
        id
        label
        skillReport {
          title
          href
        }
        iconLink {
          title
          href
        }
        selectedIconLink {
          title
          href
        }
        subSkills {
          id
          label
          description
        }
      }
      deletedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      source {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const createReportResult = /* GraphQL */ `
  mutation CreateReportResult(
    $input: CreateReportResultInput!
    $condition: ModelReportResultConditionInput
  ) {
    createReportResult(input: $input, condition: $condition) {
      id
      reportID
      userID
      userSub
      formID
      responseID
      skillScores {
        skillID
        score
        maxScore
        subSkillScores {
          subSkillID
          score
          maxScore
          scoreKey
        }
      }
      deletedAt
      enterpriseID
      archived
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        sub
        name
        firstName
        lastName
        email
        enabled
        status
        roles
        lastActive
        deletedAt
        enterpriseID
        phoneNumber
        designation
        optin
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        groupMemberships {
          nextToken
          startedAt
        }
      }
      report {
        id
        sourceReferenceID
        sourceID
        locale
        name
        heading
        subHeading
        startCTA
        explainer
        assessment {
          source
          title
          formId
        }
        skills {
          id
          label
        }
        deletedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        source {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateReportResult = /* GraphQL */ `
  mutation UpdateReportResult(
    $input: UpdateReportResultInput!
    $condition: ModelReportResultConditionInput
  ) {
    updateReportResult(input: $input, condition: $condition) {
      id
      reportID
      userID
      userSub
      formID
      responseID
      skillScores {
        skillID
        score
        maxScore
        subSkillScores {
          subSkillID
          score
          maxScore
          scoreKey
        }
      }
      deletedAt
      enterpriseID
      archived
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        sub
        name
        firstName
        lastName
        email
        enabled
        status
        roles
        lastActive
        deletedAt
        enterpriseID
        phoneNumber
        designation
        optin
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        groupMemberships {
          nextToken
          startedAt
        }
      }
      report {
        id
        sourceReferenceID
        sourceID
        locale
        name
        heading
        subHeading
        startCTA
        explainer
        assessment {
          source
          title
          formId
        }
        skills {
          id
          label
        }
        deletedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        source {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteReportResult = /* GraphQL */ `
  mutation DeleteReportResult(
    $input: DeleteReportResultInput!
    $condition: ModelReportResultConditionInput
  ) {
    deleteReportResult(input: $input, condition: $condition) {
      id
      reportID
      userID
      userSub
      formID
      responseID
      skillScores {
        skillID
        score
        maxScore
        subSkillScores {
          subSkillID
          score
          maxScore
          scoreKey
        }
      }
      deletedAt
      enterpriseID
      archived
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        sub
        name
        firstName
        lastName
        email
        enabled
        status
        roles
        lastActive
        deletedAt
        enterpriseID
        phoneNumber
        designation
        optin
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        groupMemberships {
          nextToken
          startedAt
        }
      }
      report {
        id
        sourceReferenceID
        sourceID
        locale
        name
        heading
        subHeading
        startCTA
        explainer
        assessment {
          source
          title
          formId
        }
        skills {
          id
          label
        }
        deletedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        source {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createReportSubmission = /* GraphQL */ `
  mutation CreateReportSubmission(
    $input: CreateReportSubmissionInput!
    $condition: ModelReportSubmissionConditionInput
  ) {
    createReportSubmission(input: $input, condition: $condition) {
      id
      reportID
      userID
      userSub
      status
      deletedAt
      enterpriseID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateReportSubmission = /* GraphQL */ `
  mutation UpdateReportSubmission(
    $input: UpdateReportSubmissionInput!
    $condition: ModelReportSubmissionConditionInput
  ) {
    updateReportSubmission(input: $input, condition: $condition) {
      id
      reportID
      userID
      userSub
      status
      deletedAt
      enterpriseID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteReportSubmission = /* GraphQL */ `
  mutation DeleteReportSubmission(
    $input: DeleteReportSubmissionInput!
    $condition: ModelReportSubmissionConditionInput
  ) {
    deleteReportSubmission(input: $input, condition: $condition) {
      id
      reportID
      userID
      userSub
      status
      deletedAt
      enterpriseID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createTypeformResponse = /* GraphQL */ `
  mutation CreateTypeformResponse(
    $input: CreateTypeformResponseInput!
    $condition: ModelTypeformResponseConditionInput
  ) {
    createTypeformResponse(input: $input, condition: $condition) {
      id
      formID
      userID
      assessmentType
      payload
      deletedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateTypeformResponse = /* GraphQL */ `
  mutation UpdateTypeformResponse(
    $input: UpdateTypeformResponseInput!
    $condition: ModelTypeformResponseConditionInput
  ) {
    updateTypeformResponse(input: $input, condition: $condition) {
      id
      formID
      userID
      assessmentType
      payload
      deletedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteTypeformResponse = /* GraphQL */ `
  mutation DeleteTypeformResponse(
    $input: DeleteTypeformResponseInput!
    $condition: ModelTypeformResponseConditionInput
  ) {
    deleteTypeformResponse(input: $input, condition: $condition) {
      id
      formID
      userID
      assessmentType
      payload
      deletedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createCourseRegistration = /* GraphQL */ `
  mutation CreateCourseRegistration(
    $input: CreateCourseRegistrationInput!
    $condition: ModelCourseRegistrationConditionInput
  ) {
    createCourseRegistration(input: $input, condition: $condition) {
      id
      courseID
      userID
      userSub
      status
      deadline
      deletedAt
      enterpriseID
      assignedByUserID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateCourseRegistration = /* GraphQL */ `
  mutation UpdateCourseRegistration(
    $input: UpdateCourseRegistrationInput!
    $condition: ModelCourseRegistrationConditionInput
  ) {
    updateCourseRegistration(input: $input, condition: $condition) {
      id
      courseID
      userID
      userSub
      status
      deadline
      deletedAt
      enterpriseID
      assignedByUserID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteCourseRegistration = /* GraphQL */ `
  mutation DeleteCourseRegistration(
    $input: DeleteCourseRegistrationInput!
    $condition: ModelCourseRegistrationConditionInput
  ) {
    deleteCourseRegistration(input: $input, condition: $condition) {
      id
      courseID
      userID
      userSub
      status
      deadline
      deletedAt
      enterpriseID
      assignedByUserID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createCourseRegistrationActivity = /* GraphQL */ `
  mutation CreateCourseRegistrationActivity(
    $input: CreateCourseRegistrationActivityInput!
    $condition: ModelCourseRegistrationActivityConditionInput
  ) {
    createCourseRegistrationActivity(input: $input, condition: $condition) {
      id
      courseRegistrationID
      userID
      userSub
      taskID
      taskType
      courseID
      status
      deletedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        sub
        name
        firstName
        lastName
        email
        enabled
        status
        roles
        lastActive
        deletedAt
        enterpriseID
        phoneNumber
        designation
        optin
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        groupMemberships {
          nextToken
          startedAt
        }
      }
      courseRegistration {
        id
        courseID
        userID
        userSub
        status
        deadline
        deletedAt
        enterpriseID
        assignedByUserID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateCourseRegistrationActivity = /* GraphQL */ `
  mutation UpdateCourseRegistrationActivity(
    $input: UpdateCourseRegistrationActivityInput!
    $condition: ModelCourseRegistrationActivityConditionInput
  ) {
    updateCourseRegistrationActivity(input: $input, condition: $condition) {
      id
      courseRegistrationID
      userID
      userSub
      taskID
      taskType
      courseID
      status
      deletedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        sub
        name
        firstName
        lastName
        email
        enabled
        status
        roles
        lastActive
        deletedAt
        enterpriseID
        phoneNumber
        designation
        optin
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        groupMemberships {
          nextToken
          startedAt
        }
      }
      courseRegistration {
        id
        courseID
        userID
        userSub
        status
        deadline
        deletedAt
        enterpriseID
        assignedByUserID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteCourseRegistrationActivity = /* GraphQL */ `
  mutation DeleteCourseRegistrationActivity(
    $input: DeleteCourseRegistrationActivityInput!
    $condition: ModelCourseRegistrationActivityConditionInput
  ) {
    deleteCourseRegistrationActivity(input: $input, condition: $condition) {
      id
      courseRegistrationID
      userID
      userSub
      taskID
      taskType
      courseID
      status
      deletedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        sub
        name
        firstName
        lastName
        email
        enabled
        status
        roles
        lastActive
        deletedAt
        enterpriseID
        phoneNumber
        designation
        optin
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        groupMemberships {
          nextToken
          startedAt
        }
      }
      courseRegistration {
        id
        courseID
        userID
        userSub
        status
        deadline
        deletedAt
        enterpriseID
        assignedByUserID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const createAssignmentResponse = /* GraphQL */ `
  mutation CreateAssignmentResponse(
    $input: CreateAssignmentResponseInput!
    $condition: ModelAssignmentResponseConditionInput
  ) {
    createAssignmentResponse(input: $input, condition: $condition) {
      id
      courseRegistrationID
      userID
      userSub
      taskID
      source
      formID
      responseID
      score
      totalScore
      deletedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateAssignmentResponse = /* GraphQL */ `
  mutation UpdateAssignmentResponse(
    $input: UpdateAssignmentResponseInput!
    $condition: ModelAssignmentResponseConditionInput
  ) {
    updateAssignmentResponse(input: $input, condition: $condition) {
      id
      courseRegistrationID
      userID
      userSub
      taskID
      source
      formID
      responseID
      score
      totalScore
      deletedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteAssignmentResponse = /* GraphQL */ `
  mutation DeleteAssignmentResponse(
    $input: DeleteAssignmentResponseInput!
    $condition: ModelAssignmentResponseConditionInput
  ) {
    deleteAssignmentResponse(input: $input, condition: $condition) {
      id
      courseRegistrationID
      userID
      userSub
      taskID
      source
      formID
      responseID
      score
      totalScore
      deletedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      userID
      userSub
      title
      message
      read
      deletedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        sub
        name
        firstName
        lastName
        email
        enabled
        status
        roles
        lastActive
        deletedAt
        enterpriseID
        phoneNumber
        designation
        optin
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        groupMemberships {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      userID
      userSub
      title
      message
      read
      deletedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        sub
        name
        firstName
        lastName
        email
        enabled
        status
        roles
        lastActive
        deletedAt
        enterpriseID
        phoneNumber
        designation
        optin
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        groupMemberships {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      userID
      userSub
      title
      message
      read
      deletedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        sub
        name
        firstName
        lastName
        email
        enabled
        status
        roles
        lastActive
        deletedAt
        enterpriseID
        phoneNumber
        designation
        optin
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        groupMemberships {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createInvitation = /* GraphQL */ `
  mutation CreateInvitation(
    $input: CreateInvitationInput!
    $condition: ModelInvitationConditionInput
  ) {
    createInvitation(input: $input, condition: $condition) {
      id
      email
      status
      inviterID
      inviterEnterpriseID
      inviteeID
      roles
      groupID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      inviter {
        id
        sub
        name
        firstName
        lastName
        email
        enabled
        status
        roles
        lastActive
        deletedAt
        enterpriseID
        phoneNumber
        designation
        optin
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        groupMemberships {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateInvitation = /* GraphQL */ `
  mutation UpdateInvitation(
    $input: UpdateInvitationInput!
    $condition: ModelInvitationConditionInput
  ) {
    updateInvitation(input: $input, condition: $condition) {
      id
      email
      status
      inviterID
      inviterEnterpriseID
      inviteeID
      roles
      groupID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      inviter {
        id
        sub
        name
        firstName
        lastName
        email
        enabled
        status
        roles
        lastActive
        deletedAt
        enterpriseID
        phoneNumber
        designation
        optin
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        groupMemberships {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteInvitation = /* GraphQL */ `
  mutation DeleteInvitation(
    $input: DeleteInvitationInput!
    $condition: ModelInvitationConditionInput
  ) {
    deleteInvitation(input: $input, condition: $condition) {
      id
      email
      status
      inviterID
      inviterEnterpriseID
      inviteeID
      roles
      groupID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      inviter {
        id
        sub
        name
        firstName
        lastName
        email
        enabled
        status
        roles
        lastActive
        deletedAt
        enterpriseID
        phoneNumber
        designation
        optin
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        groupMemberships {
          nextToken
          startedAt
        }
      }
    }
  }
`;
